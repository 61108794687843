.progress-container, .progress-bar {
    height: 10px;
    border-radius: 13px;
}

.progress-container {
    width: 13rem;
    background: #eee;
}

.progress-bar {
    background: rgb(1, 169, 133);
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.tylohelo-logo {
    width: 13rem;
    margin-bottom: 2rem;
}

.header-logo {
    width: 4rem;
    margin-bottom: 0.8rem;
}

.content-wrapper p {
    font-size: 1.4rem;
}