.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #DADFE3;
    color: #434c5a;
    background: #fff;
    padding: 10px;
}

.cookie-banner button, .cookie-banner p {
    display: inline-block;
    margin: 0;
}

.cookie-banner p {
    font-size: 1rem;
}

.cookie-banner p a {
    color: #007bff;
}

.cookie-banner p a:hover {
    text-decoration: none;
    color: #007bffc7;
}

.cookie-banner button {
    margin-left: 10px;
    padding: 3px 8px;
}