
/* GENERAL STYLES */

.h-100vh {
  height: 100vh;
}

.img-full {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.login-heading {
  font-size: 1.8rem;
}

.color-mid-grey {
  color: #747c8e;
}

.color-dark-grey {
  color: #434c5a;
}

.mw-100p {
  max-width: 100px;
}




/* SIDEBAR */

.sidebar {
  background: #ffffff;
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.125) !important;
}

.label-small{
  font-weight: 500;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.form-control {
  height: 40px;
}

.btn {
  font-weight: 500;
  padding: 12px 16px;
  color: white !important; 
  transition: 0.3s opacity ease;
}

.btn:hover {
  opacity: 0.9;
}

.btn-main {
  color: white;
  background-color: #00B290;
  border-color: #00B290;
}

.btn-close {
  color: white;
  background-color: #dc3545;
  border-color: #dc3545;
}





/* Admin navigation */

.nav-item a {
  color: black;
  font-weight: 400;
  font-size: 1.1em;
}








/* ADMIN TOOLBAR*/

.admin-big-number {
  font-size: 60px;
  font-weight: 300;
}

.currency-name {
  font-size: 30px;
  font-weight: 600;
}

.admin-big-field {
  width: 160px;
  font-size: 40px;
  height: 60px;
  display: inline-block;
}

.nav-tabs {
  border-bottom: 2px solid #DADFE3;
}

.nav-tabs .nav-link.active {
  border: none;
  background-color: none;
  font-weight: bold;
  border-bottom: 2px solid  #00B290;
}

.nav-tabs .nav-link {
  color: black;
  border:none;
  margin-bottom: -2px;
  border-bottom: 2px solid transparent
}


.edit-mode {
  border-color: #007bff;
}

.edit-mode .card-body p {
  color:#007bff; 
}