html, body, #root {
    width: 100%;
    height: 100%;
}

#\#canvas {
    display: block;
    width: 100%;
    height: 100%;
}

.mainBtn {
    position: absolute;
    bottom: 20px;
    left: 15px;
    z-index: 1000;
}

.mainBtn a, .mainBtn button {
    background: #fff;
    color: #000;
    padding: 10px;
    border-radius: 3px;
    border-color: transparent;
}

.mainBtn a:hover, .mainBtn button:hover {
    text-decoration: none;
}

.noselect {
    -webkit-user-select: none;  /* Chrome 49+ */
    -moz-user-select: none;     /* Firefox 43+ */
    -ms-user-select: none;      /* No support yet */
    user-select: none;          /* Likely future */
}

.reload {
    background: #00B290;
    color: #fff;
    padding: 10px;
    border-radius: 3px;
}

.reload:hover {
    background: rgba(0, 178, 145, 0.555);
    color: #fff;
    text-decoration: none;
}

.configurator-container {
    overflow-y: hidden;
    width: 100%;
    height: 100%;
}